var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group mb-0" }, [
    _vm.label
      ? _c("label", { staticClass: "form-label" }, [_vm._v(_vm._s(_vm.label))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex align-items-center my-1",
        attrs: { "data-toggle": "buttons" }
      },
      _vm._l(_vm.options, function(type) {
        return _c(
          "label",
          {
            key: type.value,
            class:
              "btn d-flex align-items-center pl-2 pr-3 " +
              (_vm.current === type.value ? "selected" : "")
          },
          [
            _c("input", {
              staticClass: "mr-2",
              style: { width: "16px", height: "16px" },
              attrs: { name: "types", type: "radio" },
              domProps: {
                value: type.name,
                checked: _vm.current === type.value
              },
              on: { change: _vm.handleClick }
            }),
            _vm._v(" " + _vm._s(_vm.formatName(type)) + "\n\t\t")
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }